import React from "react"
import { ProductHero } from "../../../../../general/ProductHero/ProductHero"
import { useStaticQuery, graphql } from "gatsby"
import { ContainerElement } from "./styled"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "shutterstock_782843011.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <ProductHero
        title="3D CAD plugins en oplossingen"
        image={imageData.heroImage.childImageSharp.fluid.src}
        technicalDetails="3D - Cloud - Tekeningen - SolidWorks"
        infoTexts={["Betere productiviteit", "Lagere foutmarge", "Versneld ontwerpproces"]}
      />
    </ContainerElement>
  )
}
