import React from "react"
import "typeface-roboto-mono"
import { Footer } from "../Home/sections/Footer/Footer"
import { About } from "./sections/About/About"
import { Hero } from "./sections/Hero/Hero/Hero"
import { Info } from "./sections/Info/Info"
import { ContainerElement } from "./styled"
import { NedAir } from "./sections/NedAir/NedAir"

export const Cad: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <About />
      <Info />
      <NedAir />
      <Footer spacing/>
    </ContainerElement>
  )
}
