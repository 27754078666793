import React from "react"
import { Cad as CadComponent } from "../components/pages/Cad/Cad"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from "../data/style/variables"
import SEO from "../components/seo"

const Cad = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <SEO
      description="Op zoek naar een oplossing dat uw ontwerp- en tekenprocessen automatiseerd?"
      title="3D CAD plugins en oplossingen"
      lang="nl"
    />
    <CadComponent />
  </Layout>
)

export default Cad
