import styled from "styled-components/macro"

export const ContainerElement = styled.div``

export const ArrowContainerElement = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0px;
  flex-direction: column;
  align-items: center;
  /* bottom: -175px; */
  height: auto;
`

export const TrianglesElement = styled.img`
  margin-top: 105px;
  z-index: 1;
  max-height: 435px;
  @media (max-width: 768px) {
    display: none;
  }
  position: absolute;
  z-index: 9999;
  bottom: -125px;
  width: 100%;
  left: 0;
`
