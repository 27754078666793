import React from "react"
import { Color, FontFamily, Theme } from "../../../data/style/variables"
import { TypographyAnimation } from "..//TypographyAnimation/TypographyAnimation"
import { CtaArrow, Rotations, Sizes } from "../CtaArrow/CtaArrow"
import { Grid } from "../Grid/Grid"
import { Section } from "../Section/Section"
import {
  Paragraph,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../Typography/Typography"
import {
  ArrowElement,
  ContainerElement,
  WorkflowIndexContainer,
  WorkflowItemContainer,
  WorkflowItemImageElement,
  ImageContainerElement
} from "./styled"

type FlowGridProps = {
  title: React.ReactElement
  flowParagraphs: FlowParagraph[]
  theme: Theme
}

export type FlowParagraph = {
  title: string
  text: string
  image?: string
}

export const FlowGrid: React.FC<FlowGridProps> = ({
  title,
  flowParagraphs,
  theme,
}) => {
  const color = theme.color ? theme.color : Color.Primary

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...theme,
          detailTextColor: Color.DarkGray,
          spaced: true,
        }}
        title={title}
      >
        {flowParagraphs.map((f, idx) => (
          <Section>
            <WorkflowItemContainer>
              <TypographyAnimation index={idx}>
                <TitleH3 color={color}>{f.title}</TitleH3>
                <Paragraph color={color}>{f.text}</Paragraph>
                <WorkflowIndexContainer>
                  <Typography
                    fontFamily={FontFamily.RobotoMono}
                    titleType={TypographyTypes.span}
                    color={color}
                    fontSize={"2rem"}
                  >
                    {idx < 10 && "0"}
                    {idx + 1}
                  </Typography>
                </WorkflowIndexContainer>
                {/* Don't show arrow on last flow item. */}
                {idx + 1 < flowParagraphs.length && (
                  <ArrowElement>
                    <CtaArrow
                      rotation={Rotations.right}
                      color={color}
                      size={Sizes.medium}
                    ></CtaArrow>
                  </ArrowElement>
                )}
                {f.image && (
                  <ImageContainerElement>
                    <WorkflowItemImageElement src={f.image} />
                  </ImageContainerElement>
                )}
              </TypographyAnimation>
            </WorkflowItemContainer>
          </Section>
        ))}
      </Grid>
    </ContainerElement>
  )
}
