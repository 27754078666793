import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ArrowElement = styled.div`
  position: absolute;
  right: 50px;
  margin-top: 25px;
`

export const WorkflowItemContainer = styled.div``
export const WorkflowIndexContainer = styled.div`
  padding: 15px 0;
`

export const WorkflowItemImageElement = styled.img`
  width: 100%;
  height: auto;
  padding: 40px;
  margin-top: 35px;
`

export const WorkflowProjectContainerElement = styled.div`
  padding: 15px 0;
`

export const ImageContainerElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
