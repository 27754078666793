import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { BlackGridTheme, Color } from "../../../../../data/style/variables"
import cubeSvg from "../../../../../images/SVG/3DCadWorkflow/Cube.svg"
import recipeSvg from "../../../../../images/SVG/3DCadWorkflow/Recipe.svg"
import xmlSvg from "../../../../../images/SVG/3DCadWorkflow/Xml.svg"
import trianglesSvg from "../../../../../images/SVG/Frame 59.svg"
import { Bold } from "../../../../general/Bold/Bold"
import { FlowGrid, FlowParagraph } from "../../../../general/FlowGrid/FlowGrid"
import { Grid } from "../../../../general/Grid/Grid"
import { Section } from "../../../../general/Section/Section"
import {
  TitleH2,
  TitleH2Article,
} from "../../../../general/Typography/Typography"
import { TrianglesElement } from "./styled"

export const NedAir: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      factory: file(relativePath: { eq: "shutterstock_1502627030.jpg" }) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)
  const flowParagraphs: FlowParagraph[] = [
    {
      title: "Configuratie in XML inladen vanuit onze configurator.",
      text:
        "Artikelen met posities worden opgeslagen in een xml bestand. Dit bestand kan worden ingeladen worden in onze Plugin voor SolidWorks.",
      image: xmlSvg,
    },
    {
      title: "Automatisch samenstellen in SolidWorks als 3D object.",
      text:
        "Wanneer de xml is ingeladen wordt de tekening automatisch samengesteld en kunnen er eventuele handmatige wijzigingen uitgevoerd worden.",
      image: cubeSvg,
    },
    {
      title: "Aanbieden aan de klant ter goedkeuring.",
      text:
        "Nu de tekening klaar is kan het in een 3D view aangeboden worden aan de klant en kan de feedback verwerkt worden door revisies te maken van het ontwerp.",
      image: recipeSvg,
    },
  ]

  return (
    <>
      <Grid
        gridElementProps={{
          baseHeight: "750px",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 1)), url(${data.factory.childImageSharp.sizes.src}) no-repeat center center/cover`,
          ...BlackGridTheme,
          noOverFlowHidden: true,
          spaced: true,
          detailTextColor: Color.WhiteOpague,
        }}
        detailText={"USE CASE"}
        title={
          <TitleH2 underlined color={Color.White}>
            Één van onze 3D CAD{" "}
            <Bold background={Color.Black}>oplossingen</Bold>
          </TitleH2>
        }
      >
        <Section></Section>
        <Section></Section>
        <Section>
          {/* <ArrowContainerElement>
            <InfoSpan color={Color.Yellow}>SolidWorks</InfoSpan>
            <CtaArrow
              rotation={Rotations.down}
              color={Color.Yellow}
              size={Sizes.large}
            />
          </ArrowContainerElement> */}
          <TrianglesElement src={trianglesSvg} />
        </Section>
      </Grid>
      <FlowGrid
        theme={BlackGridTheme}
        title={
          <TitleH2Article color={BlackGridTheme.color}>
            3D tekeningen{" "}
            <Bold background={BlackGridTheme.color}>automatiseren</Bold> voor
            luchtbehandelingskasten.
          </TitleH2Article>
        }
        flowParagraphs={flowParagraphs}
      />
    </>
  )
}
